import { Component, createEffect, createMemo, createSignal, on, Show } from 'solid-js'
import { A, useLocation, useNavigate, useSearchParams } from '@solidjs/router'
import Alert from '../../shared/Alert'
import Divider from '../../shared/Divider'
import PageHeader from '../../shared/PageHeader'
import { ACCOUNT_KEY, settingStore, toastStore, userStore } from '../../store'
import { getStrictForm, setComponentPageTitle, storage } from '../../shared/util'
import TextInput from '../../shared/TextInput'
import Button from '../../shared/Button'
import { isLoggedIn } from '/web/store/api'
import { TitleCard } from '/web/shared/Card'
import { Page } from '/web/Layout'
import { useGoogleReady } from '/web/shared/hooks'

const LoginPage: Component = () => {
  setComponentPageTitle('Login')
  const store = userStore()
  const cfg = settingStore()

  const [register, setRegister] = createSignal(false)
  const [inviteCode, setInviteCode] = createSignal('')
  const location = useLocation()

  const pathname = createMemo(() => location.pathname)
  /** Friendly error message passed out of the mutation, if it exists. */
  const loginError = createMemo(() => {
    if (!store.error) return null
    if (store.error.includes('NetworkError')) {
      return "We couldn't reach our servers."
    }

    return 'Something went wrong.'
  })
  const utmSource = createMemo(() => new URLSearchParams(window.location.search).get('source'))

  createEffect(() => {
    console.log('Path:', pathname())
    console.log('UTM Source:', utmSource())

    if (pathname() === '/register') {
      setRegister(true)
      setComponentPageTitle('Register')
    }

    if (utmSource() && utmSource().toLowerCase() === 'viewgrabber') {
      console.log('Setting invite code to viewgrabber24')
      setInviteCode('VIEWGRABBER24')
    }
  })

  createEffect(async () => {
    if (pathname() === '/register') {
      setRegister(true)
      setComponentPageTitle('Register')
    }
  })

  return (
    <Page class="flex w-full flex-col items-center">
      <div class="my-4 border-b border-white/5" />
      <PageHeader
        title={<div class="flex w-full justify-center">Welcome</div>}
        subtitle={
          <div class="flex flex-wrap items-center justify-center">
            <Button size="pill" onClick={() => setRegister(false)}>
              Login
            </Button>
            &nbsp; to your account or&nbsp;
            <Button size="pill" onClick={() => setRegister(true)}>
              Register
            </Button>
            &nbsp;or continue as a guest.
          </div>
        }
      />
      <div class="w-full max-w-sm">
        <Show when={register()}>
          <RegisterForm isLoading={store.loading} setInviteCode inviteCode={inviteCode()} />
        </Show>
        <Show when={!register()}>
          <LoginForm isLoading={store.loading} />
        </Show>
        <Show when={loginError()}>
          <Divider />
          <Alert schema="error" title="Failed to log in.">
            {loginError()}
          </Alert>
        </Show>
      </div>

      <Show when={cfg.config.policies}>
        <div class="mt-2">
          By logging in or registering, you agree that you are 18 years or older and agree to the{' '}
          <A class="link" href="/terms">
            Terms
          </A>{' '}
          and{' '}
          <A class="link" href="/privacy">
            Privacy Policy
          </A>
          .
        </div>
      </Show>

      <div class="mt-8 w-full gap-4">
        <Show when={!register()}>
          <p class="flex justify-center text-xl text-[var(--hl-400)]">Why register?</p>
          <div class="flex flex-col items-center text-center">
            <p>
              You need an account to use the virtual dating services and see all the characters.
            </p>
            <p>
              If you choose to register your data will be stored and accessible on any devices you
              login with. If you lost your password, please contact us on Discord.
            </p>
          </div>
        </Show>
        <Show when={register()}>
          <p class="flex justify-center text-xl text-[var(--hl-400)]">
            Why do I need an access code?
          </p>

          <div class="mx-4 flex flex-col items-center text-center ">
            <p>
              We need to have some sort of control on the amount of people joining since we do not
              require an email for registration (we find privacy more important). You can join our
              Discord, check our Twitter or try <b>AIVO8592094</b>{' '}
              <span class="text-sm">(this code could run out)</span>
            </p>
          </div>
          <p class="flex justify-center text-xl text-[var(--hl-400)]">
            Do not register more than one account!
          </p>

          <div class="mx-4 flex flex-col items-center text-center ">
            <p>
              When using more than one account, all your accounts will have credit recharge rate
              reduced substantially.
            </p>
          </div>
        </Show>
      </div>
    </Page>
  )
}

export default LoginPage

type FormProps = {
  isLoading: boolean
  inviteCode: string
  setInviteCode: (code: string) => void
}

const RegisterForm: Component<FormProps> = (props) => {
  const navigate = useNavigate()

  const ecu = userStore.getECU()
  const register = (evt: Event) => {
    const { username, password, confirm, handle, invitecode } = getStrictForm(evt, {
      handle: 'string',
      username: 'string',
      password: 'string',
      confirm: 'string',
      invitecode: 'string',
    })

    if (!handle || !username || !password || !invitecode) return
    if (password !== confirm) {
      toastStore.warn('Passwords do not match', 2)
      return
    }

    userStore.register({ handle, username, password, invitecode }, () => navigate('/profile'))
  }

  return (
    <form onSubmit={register} class="flex flex-col gap-6">
      <div class="flex flex-col gap-2">
        <TextInput label="Display Name" fieldName="handle" placeholder="Display name" required />
        <TextInput
          label="Username"
          fieldName="username"
          autocomplete="username"
          placeholder="Username"
          required
        />
        <TextInput
          label="Password"
          fieldName="password"
          placeholder="Password"
          type="password"
          autocomplete="new-password"
          required
        />
        <TextInput
          fieldName="confirm"
          placeholder="Confirm Password"
          type="password"
          autocomplete="new-password"
          required
        />
        <TextInput
          label="Invite code"
          fieldName="invitecode"
          value={props.inviteCode}
          onInput={(e) => props.setInviteCode(e.currentTarget.value)}
          placeholder="Check below for a code!"
          required
        />
        <Show when={props.inviteCode === 'VIEWGRABBER24'}>
          <blockquote class="text-gray-500 dark:text-gray-400">
            Coming from ViewGrabber you get a free bonus. <br />
            Some even get a huge bonus!
            <br />
            <p>"Have fun, the free tier is very generous."</p>
          </blockquote>
        </Show>

        <div></div>
      </div>

      <Button type="submit" disabled={props.isLoading}>
        {props.isLoading ? 'Registering...' : 'Register'}
      </Button>
    </form>
  )
}

const LoginForm: Component<FormProps> = (props) => {
  let refGoogle: any
  const navigate = useNavigate()
  const [query] = useSearchParams()
  const loc = useLocation()
  const state = settingStore()
  const [error, setError] = createSignal<string>()
  const google = useGoogleReady()

  createEffect(() => {
    if (state.initLoading) return

    if (query.callback && isLoggedIn()) {
      for (const authUrl of state.config.authUrls) {
        if (query.callback.startsWith(authUrl)) return handleLogin()
      }
      setError('Invalid callback URL')
      return
    }
  })

  createEffect(
    on(
      () => google(),
      () => {
        const win: any = window
        const api = win.google?.accounts?.id

        if (!api) return

        if (state.config.serverConfig?.googleClientId) {
          api.initialize({
            client_id: state.config.serverConfig?.googleClientId,
            callback: (result: any) => {
              userStore.handleGoogleCallback('login', result, () => navigate('/dashboard'))
            },
          })

          api.renderButton(refGoogle, {
            theme: 'filled_black',
            size: 'large',
            type: 'standard',
            text: 'signin_with',
          })
        }
      }
    )
  )

  const handleLogin = () => {
    userStore.remoteLogin((token) => {
      location.href = `${query.callback}?access_token=${token}`
    })
  }

  const login = (evt: Event) => {
    const { username, password } = getStrictForm(evt, { username: 'string', password: 'string' })
    if (!username || !password) return

    userStore.login(username, password, () => {
      if (query.callback) {
        handleLogin()
        return
      }

      navigate('/dashboard')
    })
  }

  return (
    <form onSubmit={login} class="flex flex-col gap-6">
      <div class="flex flex-col gap-2">
        <TextInput
          fieldName="username"
          placeholder="Username"
          autocomplete="username"
          value={loc.pathname.includes('/remember') ? storage.localGetItem(ACCOUNT_KEY) || '' : ''}
          required
        />
        <TextInput
          fieldName="password"
          placeholder="Password"
          type="password"
          autocomplete="current-password"
          required
        />
      </div>

      <Show when={error()}>
        <TitleCard type="rose">{error()}</TitleCard>
      </Show>

      <Button type="submit" disabled={props.isLoading || !!error()}>
        {props.isLoading ? 'Logging in...' : 'Login'}
      </Button>

      <div
        class="flex justify-center"
        ref={(ref) => {
          refGoogle = ref
        }}
        id="g_id_onload"
        data-context="signin"
        data-ux_mode="popup"
        data-login_uri={`${location.origin}/oauth/google`}
        data-itp_support="true"
      ></div>
    </form>
  )
}
